<script>
  /**
   * A widget showcasing an amount, with a label below it.
   */
  export default {
    props: {
      /** The amount to display. */
      value: {
        type: Number,
        default: undefined,
      },
      /** How to format the amount. */
      format: {
        type: String,
        default: 'price',
        validator: (value) => ['price', 'none'].includes(value),
      },
    },
    computed: {
      hasValue() {
        return (this.value ?? null) !== null
      },
      formattedAmount() {
        if (!this.hasValue) {
          return '-'
        }
        switch (this.format) {
          case 'price':
            return this.$formatPrice(this.value, false)
          default:
            return this.value
        }
      },
    },
  }
</script>

<template>
  <div class="text-center text-primary">
    <div
      class="savings-amount font-heading font-bold text-2xl 2xs:text-3xl xl:text-5xl leading-none"
    >
      {{ formattedAmount }}
    </div>
    <div v-if="$scopedSlots.default" class="mt-1 sm:mt-2 text-sm sm:text-base leading-snug">
      <!-- @slot The label to display below the amount. Should contain text. -->
      <slot />
    </div>
  </div>
</template>

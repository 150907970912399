<script>
  import { get } from 'vuex-pathify'
  import MarketCatalogFilters from '@/components/marketplace/MarketCatalogFilters'
  import MarketCatalogProducts from '@/components/marketplace/MarketCatalogProducts'
  import screen from '@/helpers/screen'

  export default {
    components: {
      MarketCatalogFilters,
      MarketCatalogProducts,
    },
    data() {
      return {
        filtersRef: null,
        mobileExpanded: false,
      }
    },
    computed: {
      ...get('config', ['popUpMarketEnabled']),
      screen,
    },
    watch: {
      mobileExpanded() {
        if (!this.mobileExpanded) {
          this.filtersRef.scrollTop = 0
        }
      },
    },
  }
</script>

<template>
  <section :class="`pb-20 ${popUpMarketEnabled ? '' : 'lg:pt-20'}`" data-cy="market-catalog">
    <template v-if="popUpMarketEnabled">
      <div
        v-ref="filtersRef"
        :class="`catalog-filters catalog-filters--pop-up
          ${!screen.lg ? 'bg-white' : ''}
          ${!mobileExpanded ? 'sticky' : ''}
          ${mobileExpanded ? 'w-full h-full fixed overflow-y-auto pb-30' : ''}`"
      >
        <MarketCatalogFilters
          data-cy="market-filters"
          @mobileExpanded="mobileExpanded = !mobileExpanded"
        />
      </div>
      <BaseContainer max-width="2xl">
        <div class="grid grid-cols-1 lg:grid-cols-5 gap-x-10px">
          <div class="col-span-1 lg:col-span-5 mt-2 md:mt-6">
            <MarketCatalogProducts />
          </div>
        </div>
      </BaseContainer>
    </template>
    <BaseContainer v-else max-width="2xl">
      <div class="lg:grid-cols-5 grid grid-cols-1 gap-x-10px">
        <!-- on mobile, filters section should be sticky. when accordion is expanded, it should act as a menu  -->
        <div
          v-ref="filtersRef"
          :class="`catalog-filters
          ${!screen.lg ? 'bg-white' : ''}
          ${!screen.lg && !mobileExpanded ? 'sticky' : ''}
          ${mobileExpanded ? 'w-full h-full fixed overflow-y-auto pb-30' : ''}`"
        >
          <BaseContainer>
            <MarketCatalogFilters
              data-cy="market-filters"
              @mobileExpanded="mobileExpanded = !mobileExpanded"
            />
          </BaseContainer>
        </div>
        <div class="col-span-1 lg:col-span-4 mt-10 lg:mt-0">
          <MarketCatalogProducts />
        </div>
      </div>
    </BaseContainer>
  </section>
</template>

<style lang="postcss">
  .catalog-filters {
    @apply col-span-1 z-40;

    top: 68px;

    @screen lg {
      @apply py-0 border-0;
    }

    &:not(.catalog-filters--pop-up) {
      @apply -mx-6;

      @screen sm {
        @apply -mx-9;
      }
      @screen lg {
        @apply static pr-4;
      }
    }

    &--pop-up {
      @screen lg {
        @apply col-span-5;
      }
    }
  }
</style>

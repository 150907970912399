<script>
  /**
   * A countdown with days, hours, minutes, and seconds left.
   */
  export default {
    props: {
      /** The time left, in seconds. */
      value: {
        type: Number,
        default: undefined,
      },
    },
    data() {
      const hoursInADay = 24
      const minutesInAnHour = 60
      const secondsInAMinute = 60
      return {
        hoursInADay,
        minutesInAnHour,
        secondsInAMinute,
        secondsInAnHour: secondsInAMinute * minutesInAnHour,
        secondsInADay: secondsInAMinute * minutesInAnHour * hoursInADay,
      }
    },
    computed: {
      hasValue() {
        return (this.value ?? null) !== null
      },
      days() {
        return this.hasValue ? Math.floor(this.value / this.secondsInADay) : '--'
      },
      hours() {
        return this.hasValue
          ? Math.floor(this.value / this.secondsInAnHour) % this.hoursInADay
          : '--'
      },
      minutes() {
        return this.hasValue
          ? Math.floor(this.value / this.secondsInAMinute) % this.minutesInAnHour
          : '--'
      },
      seconds() {
        return this.hasValue ? this.value % this.secondsInAMinute : '--'
      },
      formattedTime() {
        const days = this.formatTimeComponent(this.days)
        const hours = this.formatTimeComponent(this.hours)
        const minutes = this.formatTimeComponent(this.minutes)
        return `${days} : ${hours} : ${minutes} :`
      },
      formattedSeconds() {
        const seconds = this.formatTimeComponent(this.seconds)
        return `${seconds}`
      },
    },
    methods: {
      formatTimeComponent(value) {
        return String(value).padStart(2, '0')
      },
    },
  }
</script>

<template>
  <div class="flex justify-center">
    <div class="w-48 2xs:w-56 xl:w-72 mx-auto text-center">
      <div
        class="flex justify-center font-heading font-bold text-2xl 2xs:text-3xl xl:text-5xl leading-none tabular-nums text-primary"
      >
        {{ formattedTime }}
        <div class="w-8 2xs:w-10">&nbsp;{{ formattedSeconds }}</div>
      </div>
      <div
        class="ml-1 lg:ml-2 xl:ml-4 mr-2 lg:mr-3 mt-1 sm:mt-2 flex text-4xs xl:text-2xs text-center leading-snug"
      >
        <span class="flex-1">Day</span>
        <span class="flex-1">Hour</span>
        <span class="flex-1">Min</span>
        <span class="flex-1">Sec</span>
      </div>
    </div>
  </div>
</template>

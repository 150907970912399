<script>
  import { get } from 'vuex-pathify'
  import WidgetProgress from '@/components/marketplace/WidgetProgress'

  export default {
    components: {
      WidgetProgress,
    },
    props: {
      type: {
        type: String,
        default: 'seasonal',
        validator: (value) => ['upsell', 'seasonal'].includes(value),
      },
    },
    computed: {
      ...get('account', ['minTotalForFreeShipping']),
      cart() {
        return this.type === 'seasonal' ? 'marketplaceCart' : 'upsell'
      },
      remainingAmountForFreeShipping() {
        return this.lookupCartProp('remainingAmountForFreeShipping')
      },
    },
    methods: {
      lookupCartProp(prop) {
        return this.$store.get(`${this.cart}/${prop}`) ?? null
      },
    },
  }
</script>

<template>
  <WidgetProgress
    :value="lookupCartProp('subtotal')"
    :target-value="minTotalForFreeShipping"
    :decrease-width-for-mobile="type === 'upsell'"
  >
    <template v-if="lookupCartProp('hasFreeShipping')">
      You have <span class="font-semibold">FREE SHIPPING</span>
    </template>
    <template v-else-if="remainingAmountForFreeShipping">
      <span class="font-semibold">{{ $formatPrice(remainingAmountForFreeShipping) }}</span>
      away from <span class="font-semibold">FREE SHIPPING</span>
    </template>
  </WidgetProgress>
</template>

<script>
  import { get } from 'vuex-pathify'

  export default {
    props: {
      side: {
        type: String,
        required: true,
        validator: (value) => ['left', 'right'].includes(value),
      },
    },
    data() {
      return {
        activeSlideIndex: 0,
      }
    },
    computed: {
      ...get('marketplaceCart', [
        'cartSavings',
        'remainingAmountForFreeShipping',
        'hasFreeShipping',
      ]),
      slides() {
        const slides = []
        if (this.hasFreeShipping) {
          slides.push('freeShipping')
        }
        if (this.cartSavings) {
          slides.push('cartSavings')
        }
        return slides
      },
      activeSlide() {
        return this.slides[this.activeSlideIndex] ?? this.slides[0]
      },
    },
    created() {
      const interval = setInterval(() => {
        if (this.activeSlideIndex >= this.slides.length - 1) {
          this.activeSlideIndex = 0
        } else {
          this.activeSlideIndex++
        }
      }, 5000)
      this.$once('hook:beforeDestroy', () => {
        clearInterval(interval)
      })
    },
  }
</script>

<template>
  <div
    :class="`flex items-center h-8 ${
      side === 'right' ? 'px-8 border-r border-gray-active text-right' : 'pr-4'
    } leading-tight`"
  >
    <BaseTransitionFade>
      <div v-if="activeSlide === 'freeShipping'" key="freeShipping" class="duration-200">
        <template v-if="hasFreeShipping"
          >You have <span class="font-semibold">FREE SHIPPING</span></template
        >
        <template v-else>
          <span class="font-semibold">{{ $formatPrice(remainingAmountForFreeShipping) }}</span>
          away from FREE SHIPPING
        </template>
      </div>
      <div v-if="activeSlide === 'cartSavings'" key="cartSavings" class="duration-200">
        You’ve saved <span class="font-semibold">{{ $formatPrice(cartSavings) }}</span>
      </div>
    </BaseTransitionFade>
  </div>
</template>

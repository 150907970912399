<script>
  import { get } from 'vuex-pathify'
  import VueRouter from 'vue-router'
  import router from '@/router'
  import { events } from '@/helpers/gtm'
  import screen from '@/helpers/screen'

  export default {
    data() {
      return {
        isBrandsExpanded: false,
        mobileExpanded: false,
        categoryExpanded: [],
      }
    },
    computed: {
      ...get('marketplace', [
        'showFavoritesOnly',
        'filters',
        'getFiltersCount',
        'allTags',
        'categories',
        'brands',
        'tagColors',
        'tagTextColors',
      ]),
      ...get('config', ['popUpMarketEnabled']),
      screen,
      visibleBrands() {
        if (this.isBrandsExpanded) {
          return this.brands
        }
        return this.brands.slice(0, 20)
      },
    },
    watch: {
      categories: {
        immediate: true,
        handler(newValue, oldValue) {
          // When the categories load, expand any parent category that contains checked children
          if (!oldValue && newValue) {
            const categoriesToExpand = this.categories.filter((category) =>
              category.children?.some((childCategory) => this.isCategoryChecked(childCategory))
            )
            categoriesToExpand.forEach((category) => {
              this.setCategoryExpanded(category, true)
            })
          }
        },
      },
    },
    methods: {
      setShowFavoritesOnly(showFavoritesOnly) {
        this.$store.set('marketplace/showFavoritesOnly', showFavoritesOnly)
      },
      isFilterChecked(name, value) {
        return this.filters[name].includes(String(value))
      },
      async updateRoute() {
        try {
          await router.replace(this.$store.get('marketplace/pathWithFilters'))
        } catch (error) {
          // Don’t throw an error if the navigation is duplicated
          if (VueRouter.isNavigationFailure(error, VueRouter.NavigationFailureType.duplicated)) {
            return
          }
          return Promise.reject(error)
        }
      },
      setFilterChecked(name, value, checked) {
        const updatedFilterValues = this.filters[name].filter(
          (filterValue) => filterValue !== String(value)
        )
        if (checked) {
          updatedFilterValues.push(String(value))
        }
        this.$store.set('marketplace/filters', {
          ...this.filters,
          [name]: updatedFilterValues,
        })
        this.updateRoute()

        if (checked) {
          events.log({
            name: 'checked filter',
            data: {
              filter: value,
              filterType: name,
              filterCount: this.getFiltersCount,
            },
          })
        }
      },
      isTagChecked(tag) {
        return this.isFilterChecked('tags', tag.slug)
      },
      setTagChecked(tag, checked) {
        this.setFilterChecked('tags', tag.slug, checked)
      },
      isCategoryChecked(category) {
        return this.isFilterChecked('categories', category.slug)
      },
      setCategoryChecked(category, checked, parent = null) {
        this.setFilterChecked('categories', category.slug, checked)
        // checking a child will uncheck parent
        if (checked && parent) {
          this.setFilterChecked('categories', parent.slug, false)
        }
      },
      isCategoryExpanded(category) {
        return this.categoryExpanded[category.slug] ?? false
      },
      setCategoryExpanded(category, expanded) {
        this.categoryExpanded[category.slug] = expanded
      },
      isBrandChecked(index) {
        return this.isFilterChecked('brands', index)
      },
      setBrandChecked(index, checked) {
        this.setFilterChecked('brands', index, checked)
      },
      clearFilters() {
        this.$store.set('marketplace/filters', {})
        this.toggleMobileFilters()
        this.updateRoute()
      },
      toggleMobileFilters() {
        this.mobileExpanded = !this.mobileExpanded
        this.$emit('mobileExpanded')
      },
    },
  }
</script>

<template>
  <div :class="`static ${popUpMarketEnabled ? 'bg-dawn' : ''}`">
    <UtilityConditionalWrapper :wrap="popUpMarketEnabled" tag="BaseContainer" max-width="2xl">
      <div
        :class="`sticky top-0 z-20 flex items-center bg-dawn ${
          popUpMarketEnabled
            ? 'justify-between py-3'
            : 'lg:static lg:flex-col lg:items-stretch lg:bg-transparent p-6 -mx-6 sm:-mx-9 lg:mx-0 lg:p-0'
        }`"
      >
        <div v-if="popUpMarketEnabled" class="font-semibold"> Shop Bundles </div>
        <div v-if="!popUpMarketEnabled" class="static flex flex-grow lg:mb-8">
          <button type="button" class="static lg:hidden rounded-sm">
            <!-- Allow to click anywhere in the "Filters" section -->
            <span class="absolute inset-x-0 top-0 h-19" />
            <span class="flex items-center" data-cy="filters-toggle" @click="toggleMobileFilters">
              <h2 class="font-semibold">
                Filters {{ getFiltersCount ? `(${getFiltersCount})` : '' }}
              </h2>
              <BaseIcon
                :size="5"
                :class="`ml-2 flex-shrink-0 transform ${
                  mobileExpanded ? '-rotate-180' : ''
                } transition duration-200`"
              >
                <IconChevronDown />
              </BaseIcon>
            </span>
          </button>
        </div>

        <span class="flex items-center">
          <BaseIcon :size="3" class="mr-1 stroke-current text-summer">
            <IconHeart />
          </BaseIcon>
          <span class="font-semibold">Favorites</span>
          <BaseSwitch
            data-cy="favorites-toggle"
            :checked="showFavoritesOnly"
            label-position="left"
            @change="setShowFavoritesOnly($event)"
          >
          </BaseSwitch>
        </span>
      </div>
    </UtilityConditionalWrapper>

    <component
      :is="screen.lg ? 'div' : 'BaseSlideUpDown'"
      v-if="!popUpMarketEnabled"
      :expanded="mobileExpanded"
    >
      <div>
        <BaseAccordion :expanded="true" button-inner-classes="py-1" class="mt-8">
          <template v-slot:buttonLabel>Tags</template>
          <template v-slot>
            <UtilityRender
              tag="ul"
              class="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-1 gap-10px py-3"
              children-wrapper-tag="li"
              children-wrapper-classes="flex"
            >
              <BaseCheckbox
                v-for="tag in allTags"
                :key="tag.slug"
                :checked="isTagChecked(tag)"
                size="sm"
                vertical-alignment="center"
                @change="setTagChecked(tag, $event)"
              >
                <BaseTag
                  :label="tag.title"
                  :color="tagColors[tag.slug]"
                  :text-color="tagTextColors[tag.slug]"
                >
                  <template v-if="tag.slug === 'holiday-delivery'">
                    <div class="flex items-center mx-2px">
                      <BaseIcon class="w-5 mr-1 mb-2px">
                        <IconRushShipping />
                      </BaseIcon>
                      <span
                        class="capitalize font-medium"
                        data-cy="market-catalog-filters-holiday-delivery"
                      >
                        Holiday Delivery
                      </span>
                    </div>
                  </template>
                </BaseTag>
              </BaseCheckbox>
            </UtilityRender>
          </template>
        </BaseAccordion>

        <BaseAccordion :expanded="true" button-inner-classes="py-1" class="mt-6">
          <template v-slot:buttonLabel>Categories</template>
          <template v-slot>
            <UtilityRender
              tag="ul"
              class="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-1 gap-10px py-3"
              children-wrapper-tag="li"
            >
              <BaseAccordion
                v-for="category in categories"
                :key="category.slug"
                :expanded="isCategoryExpanded(category)"
                @toggle="setCategoryExpanded(category, $event)"
              >
                <template v-slot:button="{ expanded, aria, toggle }">
                  <div class="flex justify-between">
                    <BaseCheckbox
                      data-cy="categories"
                      :checked="isCategoryChecked(category)"
                      size="sm"
                      class="z-10"
                      @change="
                        setCategoryChecked(category, $event)
                        if ($event && !expanded) toggle()
                      "
                    >
                      {{ category.title }}
                    </BaseCheckbox>
                    <button
                      v-if="category.children.length"
                      type="button"
                      class="absolute left-0 top-0 flex justify-end items-center w-full h-full rounded-sm"
                      v-bind="aria"
                      @click="toggle"
                    >
                      <BaseIcon
                        :label="expanded ? 'Collapse' : 'Expand'"
                        :size="4"
                        :class="`mr-1 mt-2px transform ${
                          expanded ? '-rotate-180' : ''
                        } transition duration-200`"
                      >
                        <IconChevronDown />
                      </BaseIcon>
                    </button>
                  </div>
                </template>
                <template v-if="category.children.length" v-slot>
                  <UtilityRender
                    tag="ul"
                    class="grid grid-cols-1 gap-10px pl-6 py-3"
                    children-wrapper-tag="li"
                    children-wrapper-classes="flex"
                  >
                    <BaseCheckbox
                      v-for="childCategory in category.children"
                      :key="childCategory.slug"
                      :checked="isCategoryChecked(childCategory)"
                      size="sm"
                      @change="setCategoryChecked(childCategory, $event, category)"
                    >
                      {{ childCategory.title }}
                    </BaseCheckbox>
                  </UtilityRender>
                </template>
              </BaseAccordion>
            </UtilityRender>
          </template>
        </BaseAccordion>

        <BaseAccordion :expanded="true" button-inner-classes="py-1" class="mt-6">
          <template v-slot:buttonLabel>Brands</template>
          <template v-slot>
            <UtilityRender
              tag="ul"
              class="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-1 gap-10px py-3"
              children-wrapper-tag="li"
              children-wrapper-classes="flex"
            >
              <BaseCheckbox
                v-for="(brand, index) in visibleBrands"
                :key="index"
                :checked="isBrandChecked(index)"
                size="sm"
                @change="setBrandChecked(index, $event)"
              >
                {{ brand }}
              </BaseCheckbox>
            </UtilityRender>
            <div class="mt-2 text-2xs">
              <BaseLinkStyled color="black" @click="isBrandsExpanded = !isBrandsExpanded">
                {{ isBrandsExpanded ? 'Show less' : 'Show more' }}
              </BaseLinkStyled>
            </div>
          </template>
        </BaseAccordion>

        <div class="mt-10 lg:hidden">
          <BaseButton @click="toggleMobileFilters">Apply</BaseButton>
          <div class="mt-4 flex justify-center text-sm">
            <BaseLinkStyled
              color="black"
              data-cy="market-catalog-filters-clear"
              @click="clearFilters"
              >Clear</BaseLinkStyled
            >
          </div>
        </div>
      </div>
    </component>
  </div>
</template>

<script>
  /**
   * A progress bar ranging from 0 to a given target value, optionally formatted as a price, with a label below it.
   */
  export default {
    props: {
      /** The current value, which should be between 0 and the target value (inclusively). */
      value: {
        type: Number,
        default: undefined,
      },
      /** The target value, i.e. the number appearing on the right side of the progress bar. */
      targetValue: {
        type: Number,
        default: undefined,
      },
      /** How to format the values. */
      format: {
        type: String,
        default: 'price',
        validator: (value) => ['price', 'none'].includes(value),
      },
      /** Whether there needs to be a smaller width for mobile. Seen in the upsell market hero where it sits on the same line as another widget vs. the Add-On Market where it takes the full width. */
      decreaseWidthForMobile: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      hasValues() {
        return (this.value ?? null) !== null && (this.targetValue ?? null) !== null
      },
      progress() {
        if (!this.hasValues) {
          return undefined
        }
        return Math.max(0, Math.min(1, this.value / this.targetValue))
      },
      remaining() {
        if (!this.hasValues) {
          return undefined
        }
        return Math.max(0, Math.min(this.targetValue, this.targetValue - this.value))
      },
    },
    methods: {
      formatValue(value) {
        if (!this.hasValues) {
          return '-'
        }
        switch (this.format) {
          case 'price':
            return this.$formatPrice(value)
          default:
            return value
        }
      },
    },
  }
</script>

<template>
  <div>
    <div
      class="progress-amount flex items-center font-heading font-bold text-2xl 2xs:text-3xl xl:text-5xl leading-none"
    >
      <div class="mr-4 sm:mr-5 flex-shrink-0 text-primary">{{ formatValue(0) }}</div>
      <div
        :class="`flex-grow h-22px ${
          decreaseWidthForMobile ? 'min-w-23' : 'min-w-47'
        } lg:min-w-30 rounded-full border border-primary`"
      >
        <div
          class="absolute left-0 inset-y-0 min-w-5 sm:min-w-6 rounded-full bg-primary transition-width duration-600"
          :style="{ width: `${progress * 100}%` }"
        />
      </div>
      <div class="ml-4 sm:ml-5 flex-shrink-0 text-primary">{{ formatValue(targetValue) }}</div>
    </div>
    <div class="mt-1 sm:mt-2 text-4xs xl:text-2xs text-center leading-snug">
      <!-- @slot The label to display below the progress bar. Should contain text. Defaults to the current value. -->
      <slot :remaining="formatValue(remaining)">
        {{ formatValue(value) }}
      </slot>
    </div>
  </div>
</template>

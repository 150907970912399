<script>
  /**
   * A layout component that arranges `ProductCard` components into a grid.
   */
  export default {
    props: {
      /** The number of product cards per row. */
      columns: {
        type: Number,
        default: 4,
        validator: (value) => value >= 2 && value <= 6,
      },
    },
  }
</script>

<template>
  <!-- for purgecss: md:grid-cols-2 md:grid-cols-3 md:grid-cols-4 md:grid-cols-5 md:grid-cols-6 -->
  <div :class="`grid grid-cols-2 md:grid-cols-${columns} gap-x-4 md:gap-x-6 gap-y-10 md:gap-y-12`">
    <slot />
  </div>
</template>

<script>
  import { get } from 'vuex-pathify'
  import ProductCard from '@/components/marketplace/ProductCard'

  /**
   * A slider component that renders `ProductCard` components. Accepts an array of `products` and a `hasRank` boolean used to show product rank (defaults to `false`).
   */
  export default {
    components: {
      ProductCard,
    },
    props: {
      /** An array of product objects. See `ProductCard` for detailed information on object properties. */
      products: {
        type: Array,
        required: true,
      },
      type: {
        type: String,
        default: 'marketplace',
        validator: (value) => ['marketplace', 'choicePlus', 'essentials', 'upsell'].includes(value),
      },
      /** Whether to show product ranks. */
      hasRank: {
        type: Boolean,
        default: false,
      },
      /** Whether product cards should have a “Add to cart” button. */
      hasAddToCartButton: {
        type: Boolean,
        default: false,
      },
      /** Whether product cards should have a "Quick Add" or "Add to Waitlist" button on hover. */
      hasQuickAddButton: {
        type: Boolean,
        default: false,
      },
      /** The context in which this slider is shown. Keep `undefined` for no special context. */
      context: {
        type: String,
        default: undefined,
        validator: (value) => ['modal'].includes(value),
      },
      /** The number of slides per view. If not set, it will be determined automatically based on the slides’ width. */
      slidesPerView: {
        type: Number,
        default: undefined,
      },
      /** Whether the product title should be truncated to 1 line and end in ellipses */
      truncateProductTitle: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...get('marketplaceCart', ['isMaxInCart']),
      // need to use the marketplace product card in the upsell market slider
      productCardType() {
        return this.type === 'upsell' ? 'marketplace' : this.type
      },
    },
    methods: {
      viewDetails(product) {
        if (this.type === 'marketplace') {
          const routeName =
            this.$route.name === 'MarketplaceCart' ? 'CartMarketProduct' : 'MarketProduct'
          this.$navigate({
            name: routeName,
            params: {
              productId: product.id,
              productSlug: product.slug,
              productTitle: product.title,
            },
          })
        } else if (this.type === 'upsell') {
          const routeName =
            this.$route.name === 'SubscribeMarketCheckout'
              ? 'SubscribeMarketCheckoutProduct'
              : 'SubscribeMarketProduct'
          this.$navigate({
            name: routeName,
            params: {
              productId: product.id,
              type: 'upsell',
            },
          })
        }
      },
    },
  }
</script>

<template>
  <div>
    <BaseHeading v-if="$scopedSlots.heading" size="h5" tag="h2" class="mb-3">
      <slot name="heading" />
    </BaseHeading>

    <!-- for purgecss: slider--modal -->
    <BaseSlider
      has-navigation
      :slides-per-view="slidesPerView"
      :class="`slider ${context ? `slider--${context}` : ''}`"
    >
      <BaseSliderSlide v-for="(product, index) in products" :key="product.id" class="slide">
        <ProductCard
          :product="product"
          :type="productCardType"
          :rank="hasRank ? index + 1 : undefined"
          :has-add-to-cart-button="hasAddToCartButton"
          :has-quick-add-button="hasQuickAddButton"
          :is-max-in-cart="isMaxInCart(product.defaultVariant.id)"
          :truncate-product-title="truncateProductTitle"
          :has-favorite-icon="type === 'marketplace'"
          @addToCart="$emit('addToCart', $event)"
          @addToWaitlist="$emit('addToWaitlist', $event)"
          @setFavorited="$emit('setFavorited', $event)"
          @viewDetails="viewDetails($event)"
        />
      </BaseSliderSlide>
    </BaseSlider>
  </div>
</template>

<style lang="postcss" scoped>
  /* Override BaseSlider's default CSS */
  .slider {
    @apply -mx-6px;

    /* https://vue-loader.vuejs.org/guide/scoped-css.html#mixing-local-and-global-styles */
    & >>> {
      & > .swiper-container {
        @apply overflow-hidden;
      }

      & > .swiper-navigation {
        .swiper-button-prev,
        .swiper-button-next {
          @apply bg-white border border-black text-black;

          @screen md {
            @apply -mt-18;
          }
        }

        .swiper-button-next {
          @apply -mr-2;
        }

        .swiper-button-prev {
          @apply -ml-2;
        }
      }
    }
  }

  .slide {
    @apply flex flex-col w-1/2 h-auto px-5px;

    @screen md {
      @apply w-1/4;
    }

    @screen lg {
      @apply w-1/5;
    }

    .slider--modal & {
      @screen md {
        @apply w-1/3;
      }

      @screen lg {
        @apply w-1/4;
      }
    }
  }
</style>

<script>
  /**
   * A button containing a heart icon, styled differently depending on whether the product it is associated with was favorited or not (passed with the `isFavorited` prop).
   */
  export default {
    model: {
      prop: 'isFavorited',
      event: 'change',
    },
    props: {
      /** Whether the product was favorited or not. */
      isFavorited: {
        type: Boolean,
        default: false,
      },
      /** The name of the product to add to/remove from favorites. */
      label: {
        type: String,
        default: 'this product',
      },
    },
    computed: {
      innerIsFavorited: {
        get() {
          return this.isFavorited
        },
        set(value) {
          /** Emitted when the product was added to or removed from favorites. */
          this.$emit('change', value)
        },
      },
    },
  }
</script>

<template>
  <BaseIconClickable
    :label="`${innerIsFavorited ? `Remove ${label} from favorites` : `Add ${label} to favorites`}`"
    :color="null"
    stroke
    :class="`icon ${
      innerIsFavorited
        ? 'text-summer'
        : 'text-black hover:text-gray-darker active:text-gray-darkest stroke-2'
    } `"
    :style="{ fill: innerIsFavorited ? 'currentColor' : 'none' }"
    data-cy="favorite-toggle"
    @click="innerIsFavorited = !innerIsFavorited"
  >
    <IconHeart :style="{ width: $pxToRem(22), height: $pxToRem(22) }" />
  </BaseIconClickable>
</template>

<style lang="postcss" scoped>
  .icon:active {
    animation: animate-favorite 0.15s ease-in;
  }

  @keyframes animate-favorite {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.2);
    }

    100% {
      transform: scale(1);
    }
  }
</style>

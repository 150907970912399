<script>
  export default {
    props: {
      /** The value of the button. */
      value: {
        type: String,
        required: true,
      },
      /** The label for the button. */
      name: {
        type: String,
        required: true,
      },
      /** The image source for the image in the button. */
      imgSrc: {
        type: String,
        required: true,
      },
      /** The currently selected tab. */
      selectedTab: {
        type: String,
        required: true,
      },
    },
    methods: {
      selectTab() {
        this.$emit('clicked', this.value)
      },
    },
  }
</script>

<template>
  <button
    :class="`button ${
      selectedTab === value ? 'button--selected' : ''
    } border-primary active:border-primary`"
    @click="selectTab"
  >
    <div class="tab-image flex-shrink-0">
      <BaseImage :src="imgSrc" alt="" :aspect-ratio="1" rounded />
    </div>
    <span class="label font-bold text-primary">{{ name }}</span>
  </button>
</template>

<style lang="postcss" scoped>
  .button {
    &:not(.button--selected) {
      @apply border-gray-active;

      &:focus {
        @apply border-black;
      }
    }

    @apply mt-2 mr-1 pl-1 min-w-39 min-h-12 text-xs flex flex-grow items-center flex-grow-1 border rounded-full transition duration-200;

    &--selected {
      @apply border-2;
    }

    @screen md {
      @apply mr-4 pl-2 min-h-18 text-lg;
    }

    @screen lg {
      @apply max-w-62;
    }

    &:hover,
    &:focus {
      @apply bg-gray-hover;
    }

    &:focus {
      @apply shadow-none;
    }

    &:active {
      @apply transition-none;
    }
  }

  .label {
    @apply mx-1 flex justify-center flex-grow-infinity uppercase;

    @screen md {
      @apply mx-4;
    }
  }

  .tab-image {
    @apply w-10 flex-grow-1;

    @screen md {
      @apply w-14;
    }
  }
</style>

<script>
  import { get } from 'vuex-pathify'
  import MarketModal from '@/components/marketplace/MarketModal'
  import LoginForm from '@/components/account/LoginForm'

  /**
   * Different types of modals that lock the user from accessing the market depending on their
   * subscription status/feature flags. Used for the seasonal and essentials markets.
   */
  export default {
    components: {
      MarketModal,
      LoginForm,
    },
    props: {
      /** The type of market these modals are being used in. */
      type: {
        type: String,
        required: true,
        validator: (value) => ['seasonal', 'essentials'].includes(value),
      },
    },
    data() {
      return {
        loginModalVisible: false,
      }
    },
    computed: {
      ...get('account', ['hasMarketAccess', 'loggedIn', 'isAdmin']),
      ...get('config', ['currentSeason', 'marketplaceEnabled']),
      ...get('feature', ['flags']),
      isForEssentials() {
        return this.type === 'essentials'
      },
      marketEnabled() {
        if (this.isForEssentials) {
          return this.flags['essentials-shop-enabled'] ?? false
        }
        return this.marketplaceEnabled
      },
      marketClosed() {
        return !this.marketEnabled && !this.isAdmin
      },
      marketType() {
        if (this.isForEssentials) {
          return 'Essentials Shop'
        }
        return 'Add-On Market'
      },
    },
  }
</script>

<template>
  <MarketModal
    v-if="marketEnabled && !loggedIn && !loginModalVisible"
    data-cy="market-logged-out-modal"
  >
    <h1 v-if="!isForEssentials" class="text-xl mb-6"
      ><span class="font-semibold">Alltrue</span> Add-On Market is open!</h1
    >
    <p class="mb-6">
      If you are a Member,
      <BaseLinkStyled inline underlined @click="loginModalVisible = true">log in</BaseLinkStyled>
      now to shop socially-conscious products at prices up to
      <span class="text-green">70% off retail value!</span>
    </p>
    <p>
      If you are not a Member,
      <BaseLinkStyled :to="{ name: 'SubscribePlan' }" inline underlined>join now</BaseLinkStyled>
      to receive our {{ currentSeason }} Box and access to the {{ marketType }}.
    </p>
  </MarketModal>

  <MarketModal
    v-else-if="marketEnabled && !loggedIn && loginModalVisible"
    data-cy="market-login-form-modal"
  >
    <h1 class="text-xl mb-3">Log in to your <span class="font-semibold">Alltrue</span> account</h1>
    <p
      >or <BaseLinkStyled :to="{ name: 'SubscribePlan' }" inline>Sign Up</BaseLinkStyled> for a
      Membership</p
    >
    <LoginForm />
    <BaseRichText class="mt-8">
      <p>
        Trouble logging in?
        <BaseLinkStyled :to="{ name: 'AccountResetPassword' }" inline
          >Reset your password</BaseLinkStyled
        >.
      </p>
    </BaseRichText>
  </MarketModal>

  <MarketModal v-else-if="marketEnabled && !hasMarketAccess" data-cy="market-inactive-sub-modal">
    <h1 v-if="!isForEssentials" class="text-xl mb-6"
      ><span class="font-semibold">Alltrue</span> Add-On Market is open!</h1
    >
    <p class="mb-6">
      <BaseLinkStyled :to="{ name: 'SubscribePlan' }" inline>Join today</BaseLinkStyled>
      to gain access to more than 700+ products at up to 70% off! It is only available to Alltrue
      Members, and it is one of the most popular features of the Membership!
    </p>
    <p>
      If you
      <BaseLinkStyled :to="{ name: 'SubscribePlan' }" inline>join today</BaseLinkStyled>, you will
      receive our {{ currentSeason }} Box and access to the {{ marketType }}!
    </p>
  </MarketModal>

  <MarketModal v-else-if="marketClosed">
    <p>
      The {{ marketType }} is now closed! Please stay tuned to your Account page for shipping
      updates!
    </p>
  </MarketModal>
</template>

<script>
  import { get, call } from 'vuex-pathify'
  import { DynamicScroller } from 'vue-virtual-scroller'
  import screen from '@/helpers/screen'
  import ProductCardGrid from '@/components/marketplace/ProductCardGrid'
  import ProductCard from '@/components/marketplace/ProductCard'

  export default {
    components: {
      DynamicScroller,
      ProductCardGrid,
      ProductCard,
    },
    computed: {
      ...get('products', ['loaded']),
      ...get('marketplace', ['filteredProducts']),
      ...get('marketplaceCart', ['isProductInCart', 'isMaxInCart']),
      ...get('config', ['popUpMarketEnabled']),
      screen,
      productsPerRowByMarket() {
        return this.popUpMarketEnabled ? 4 : 3
      },
      productRows() {
        const productsPerRow = this.screen.md ? this.productsPerRowByMarket : 2
        return this.filteredProducts.reduce((productRows, product, productIndex) => {
          const rowIndex = Math.floor(productIndex / productsPerRow)
          if (!productRows[rowIndex]) {
            productRows[rowIndex] = {
              id: rowIndex,
              products: [],
            }
          }
          productRows[rowIndex].products.push(product)
          return productRows
        }, [])
      },
    },
    methods: {
      ...call('marketplaceCart', ['addToCart', 'addToWaitlist']),
      ...call('marketplace', ['setFavorited']),
      viewDetails(product) {
        this.$navigate({
          name: 'MarketProduct',
          params: {
            productId: product.id,
            productSlug: product.slug,
            productTitle: product.title,
          },
        })
      },
    },
  }
</script>

<template>
  <div data-cy="market-catalog-products">
    <div v-if="!loaded" class="h-50">
      <BaseSpinner overlay="absolute" />
    </div>

    <DynamicScroller
      v-else-if="productRows.length > 0"
      v-slot="{ item: row, active }"
      :items="productRows"
      :min-item-size="340"
      :buffer="2000"
      page-mode
    >
      <DynamicScrollerItem :item="row" :active="active" :class="`${active ? '' : 'hidden'}`">
        <ProductCardGrid :columns="productsPerRowByMarket" class="pb-10 md:pb-12 -mx-4 md:m-0">
          <ProductCard
            v-for="product in row.products"
            :key="product.id"
            :product="product"
            :is-in-cart="isProductInCart(product.id)"
            :is-max-in-cart="isMaxInCart(product.defaultVariant.id)"
            is-in-plp
            type="marketplace"
            has-quick-add-button
            @addToCart="addToCart"
            @addToWaitlist="addToWaitlist"
            @setFavorited="setFavorited"
            @viewDetails="viewDetails($event)"
          />
        </ProductCardGrid>
      </DynamicScrollerItem>
    </DynamicScroller>

    <div
      v-else
      data-cy="market-catalog-products-empty"
      class="h-50 flex justify-center items-center"
    >
      No products match the selected filters.
    </div>
  </div>
</template>

<style lang="postcss">
  /* purgecss start ignore */
  @import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

  .vue-recycle-scroller__item-wrapper {
    /* Prevent clipping the focus outlines on the product cards */
    overflow: visible;
  }
  /* purgecss end ignore */
</style>

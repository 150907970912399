<script>
  import { get } from 'vuex-pathify'
  import screen from '@/helpers/screen'
  import debounce from '@/helpers/debounce'
  import HeaderTicker from '@/components/marketplace/HeaderTicker'
  import MarketHero from '@/components/marketplace/MarketHero'
  import MarketFeaturedTabs from '@/components/marketplace/MarketFeaturedTabs'
  import MarketCatalog from '@/components/marketplace/MarketCatalog'
  import MarketLockedModals from '@/views/MarketLockedModals'

  export default {
    components: {
      HeaderTicker,
      MarketHero,
      MarketFeaturedTabs,
      MarketCatalog,
      MarketLockedModals,
    },
    data() {
      return {
        heroRef: null,
        heroAboveViewport: false,
      }
    },
    computed: {
      ...get(['route']),
      ...get('config', ['currentSeason', 'marketplaceEnabled']),
      ...get('feature', ['flags']),
      ...get('account', ['loggedIn', 'hasMarketAccess', 'isAdmin']),
      ...get('marketplace', ['topTenProducts', 'isPDPView']),
      ...get('marketplaceCart', ['isCartEmpty']),
      screen,
    },
    async created() {
      this.$store.dispatch('marketplace/ensureFresh')
      this.$store.dispatch('products/ensureFresh')
      // Watch for changes to route filters
      const unwatchRoute = this.$store.watch(
        () => this.$store.get('route'),
        async () => {
          const route = this.$store.get('route')
          if (route.name === 'Market') {
            this.$store.set('marketplace/filters', {})
          }
          if (route.name === 'MarketFilters') {
            this.$store.set('marketplace/filters', route.params.filters)
          }
        },
        { immediate: true }
      )
      this.$once('hook:beforeDestroy', () => {
        unwatchRoute()
      })
    },
    mounted() {
      // Refresh the `heroAboveViewport` property
      const heroElement = this.heroRef?.$el ?? this.heroRef
      const headerOffset = this.$twToPx(17, true)
      const refreshHeroAboveViewport = () => {
        this.heroAboveViewport = heroElement.getBoundingClientRect().bottom < headerOffset
      }
      if (heroElement) {
        refreshHeroAboveViewport()
        // Also refresh it as the user scrolls, with an IntersectionObserver
        const observer = new window.IntersectionObserver(() => refreshHeroAboveViewport(), {
          // Offset the header's height
          rootMargin: `-${headerOffset}px 0px 0px 0px`,
        })
        observer.observe(heroElement)
        this.$once('hook:beforeDestroy', () => {
          observer.disconnect()
        })
        // Also refresh it when the window is resized
        const handleResize = debounce(refreshHeroAboveViewport, 200)
        window.addEventListener('resize', handleResize)
        this.$once('hook:beforeDestroy', () => {
          window.removeEventListener('resize', handleResize)
        })
      }
    },
  }
</script>

<template>
  <div>
    <Portal to="headerLogo">
      <div
        v-if="heroAboveViewport && !screen.sm && !isPDPView && !isCartEmpty"
        class="flex items-center flex-1"
      >
        <HeaderTicker side="left" />
      </div>
    </Portal>

    <Portal to="headerNav">
      <div v-if="heroAboveViewport && screen.sm" class="flex items-center flex-1">
        <HeaderTicker side="right" />
      </div>
    </Portal>

    <MarketHero v-ref="heroRef" data-cy="market-hero" />
    <MarketFeaturedTabs
      v-if="topTenProducts && topTenProducts.length > 0"
      class="overflow-hidden"
    />
    <MarketCatalog />

    <Portal to="productModal">
      <RouterView />
    </Portal>

    <Portal to="modal">
      <MarketLockedModals type="seasonal" />
    </Portal>
  </div>
</template>

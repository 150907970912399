<script>
  import { get, call } from 'vuex-pathify'
  import ProductSlider from '@/components/marketplace/ProductSlider'
  import MarketFeaturedTabButton from '@/components/marketplace/MarketFeaturedTabButton'
  import editorsPicksImage from '@/images/marketFeaturedTabs/tab-editors-picks.png'
  import dailyDealsImage from '@/images/marketFeaturedTabs/tab-daily-deals.png'
  import topTenImage from '@/images/marketFeaturedTabs/tab-top-10.png'
  import twentyFiveAndUnderImage from '@/images/marketFeaturedTabs/tab-25-and-under.png'
  import screen from '@/helpers/screen'

  export default {
    components: {
      ProductSlider,
      MarketFeaturedTabButton,
    },
    data() {
      return {
        selectedTab: null,
        categories: [
          {
            slug: 'topTenProducts',
            src: topTenImage,
          },
          {
            slug: 'five-and-under',
            src: twentyFiveAndUnderImage,
          },
          {
            slug: 'cb-picks',
            src: editorsPicksImage,
          },
          {
            slug: 'daily-deals',
            src: dailyDealsImage,
          },
        ],
      }
    },
    computed: {
      ...get('feature', ['isRushShippingEnabled']),
      ...get('marketplace', ['topTenProducts', 'tags']),
      ...get('products', ['products']),
      screen,
      isTopTenProducts() {
        return this.selectedTab === 'topTenProducts'
      },
      // getting visible categories so we don't display tabs/sliders that don't have any products in it
      visibleCategories() {
        this.categories.forEach((category) => {
          category.slider = this.getSliderProducts(category.slug)
        })

        return this.categories.filter(({ slider, slug }) => {
          if (slug === 'holidayGifts') {
            return this.isRushShippingEnabled && slider.length > 0
          }
          return slider.length > 0
        })
      },
      slidersPerView() {
        if (this.screen.xl) {
          return 6
        } else if (this.screen.lg) {
          return 5
        } else if (this.screen.sm) {
          return 4
        }
        return 2
      },
    },
    created() {
      this.selectedTab = this.visibleCategories[0].slug
    },
    methods: {
      ...call('marketplace', ['setFavorited']),
      ...call('marketplaceCart', ['addToCart', 'addToWaitlist']),
      selectTab(value) {
        this.selectedTab = value
      },
      getSliderProducts(slug) {
        switch (slug) {
          case 'topTenProducts':
            return this.topTenProducts
          case 'holidayGifts':
            return this.products
              .filter(({ defaultVariant }) => defaultVariant.rushShippingStock > 0)
              .slice(0, 20)
          case 'five-and-under':
            return this.products
              .filter(({ defaultVariant }) => defaultVariant.price <= 5)
              .slice(0, 10)
          default:
            return this.products.filter((product) => product.tags.includes(slug)).slice(0, 10)
        }
      },
      tagName(slug) {
        switch (slug) {
          case 'topTenProducts':
            return 'TOP 10 TODAY'
          case 'holidayGifts':
            return 'HOLIDAY GIFTS'
          case 'five-and-under':
            return 'FAVES UNDER $5'
          default:
            return this.tags?.find((tag) => tag.slug === slug)?.title
        }
      },
    },
  }
</script>

<template>
  <section data-cy="market-featured-tabs" class="border-t-2 border-b-2 border-black bg-dawn-lt2">
    <BaseContainer max-width="2xl">
      <div class="pt-3">
        <div class="2xs:grid 2xs:grid-cols-2 lg:flex lg:flex-wrap lg:flex-grow mb-7">
          <MarketFeaturedTabButton
            v-for="({ slug, src }, index) in visibleCategories"
            :key="index"
            :name="tagName(slug)"
            :value="slug"
            :img-src="src"
            :selected-tab="selectedTab"
            @clicked="selectTab"
          />
        </div>
      </div>
    </BaseContainer>
    <div class="mx-auto overflow-hidden pb-4 px-6 sm:px-9 lg:pb-8 xl:max-w-330">
      <div class="max-w-320 mx-auto">
        <ProductSlider
          :products="getSliderProducts(selectedTab)"
          :has-rank="isTopTenProducts"
          has-quick-add-button
          :slides-per-view="slidersPerView"
          class="market-slider"
          @addToCart="addToCart"
          @addToWaitlist="addToWaitlist"
          @setFavorited="setFavorited"
        />
      </div>
    </div>
  </section>
</template>

<style lang="postcss" scoped>
  /* purgecss start ignore */

  .market-slider {
    & >>> {
      & > .slider {
        & > .swiper-container {
          @screen lg {
            @apply overflow-visible -ml-1 -mr-1 pr-8;
          }
        }

        .slide {
          @screen lg {
            @apply px-2;
          }
        }
      }
    }
  }
</style>

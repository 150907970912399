<script>
  import { get, call } from 'vuex-pathify'
  import clone from 'clone-deep'
  import { ApiError } from '@/api'

  export default {
    props: {
      /** Whether the CTA button at the bottom of the form should be hidden */
      hideButton: {
        type: Boolean,
        default: false,
      },
      /* Whether both fields should be on one line on desktop **/
      fieldsOnOneLine: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        formRef: null,
        credentials: clone(this.$store.get('account/rememberedCredentials')),
        submitting: false,
        success: false,
        passwordHelpButtonRef: null,
        passwordHelpPanelVisible: false,
      }
    },
    computed: {
      ...get('account', ['username']),
    },
    methods: {
      ...call('account', ['login']),
      rememberCredentials() {
        this.$store.set('account/rememberedCredentials', clone(this.credentials))
      },
      blurPassword() {
        this.rememberCredentials()
        this.$emit('blurPassword')
      },
      async submit() {
        this.submitting = true
        try {
          await this.formRef.process(this.login(this.credentials))
          this.success = true
        } catch (error) {
          if (error instanceof ApiError) {
            // Don't need to do anything with expected (API) errors, so just returning
            return
          }
          return Promise.reject(error)
        } finally {
          this.submitting = false
        }
      },
    },
  }
</script>

<template>
  <BaseForm
    v-slot="{ inputsEnabled, submitEnabled, errorMessage }"
    v-ref="formRef"
    spinner
    class="mt-10 text-left"
    @submit="submit"
  >
    <BasePanelWarning v-if="errorMessage" class="mb-6">
      {{ errorMessage }}
    </BasePanelWarning>

    <BaseStack>
      <UtilityConditionalWrapper :wrap="fieldsOnOneLine" tag="div" class="md:flex">
        <BaseInput
          v-model="credentials.username"
          type="email"
          name="username"
          :disabled="!inputsEnabled"
          required
          :class="`${fieldsOnOneLine ? 'md:w-1/2' : ''}`"
          v-on="$listeners"
          @blur="rememberCredentials"
        >
          Email
        </BaseInput>
        <BaseInput
          v-model="credentials.password"
          type="password"
          name="password"
          :password-pattern="$route.name === 'SubscribeSignup'"
          :disabled="!inputsEnabled"
          required
          data-dd-privacy="mask"
          :class="`${fieldsOnOneLine ? 'md:w-1/2 mt-2 md:mt-0 ml-0 md:ml-2' : ''}`"
          v-on="$listeners"
          @blur="blurPassword"
        >
          Password
          <template v-slot:icons>
            <BaseIconInput
              v-ref="passwordHelpButtonRef"
              label="Help"
              @click="passwordHelpPanelVisible = !passwordHelpPanelVisible"
            >
              <IconHelp />
            </BaseIconInput>
          </template>
        </BaseInput>
      </UtilityConditionalWrapper>
      <div class="-mb-2">
        <BaseSlideUpDown :expanded="passwordHelpPanelVisible">
          <div class="pt-2 pb-3">
            <BasePanel :arrow-anchor="passwordHelpButtonRef">
              Passwords must be 8 or more characters, with at least one letter, one number, and one
              special character (@$!%*#?&_)
            </BasePanel>
          </div>
        </BaseSlideUpDown>
      </div>
    </BaseStack>

    <BaseButton v-if="!hideButton" type="submit" :disabled="!submitEnabled" class="mt-6">
      <slot name="buttonLabel">Continue</slot>
    </BaseButton>
  </BaseForm>
</template>

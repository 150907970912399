<script>
  import { get, call } from 'vuex-pathify'
  import dayjs from 'dayjs'
  import MarketShippingProgress from '@/components/marketplace/MarketShippingProgress'
  import WidgetCountdown from '@/components/marketplace/WidgetCountdown'
  import WidgetAmount from '@/components/marketplace/WidgetAmount'
  import screen from '@/helpers/screen'

  export default {
    components: {
      MarketShippingProgress,
      WidgetCountdown,
      WidgetAmount,
    },
    computed: {
      screen,
      ...get('config', ['marketplaceEndDate', 'marketplaceSecondsLeft', 'marketplaceEnabled']),
      ...get('marketplaceCart', ['cartSavings']),
      ...get('account', ['minTotalForFreeShipping']),
      ...get('prismic', ['seasonalMarketData']),
      showCountdown() {
        return this.marketplaceSecondsLeft > 0
      },
      formattedMarketplaceEndDate() {
        return dayjs(this.marketplaceEndDate).format('MMMM DD')
      },
      header() {
        return this.seasonalMarketData?.title1?.[0]?.text ?? 'Spring Add-On Market'
      },

      image() {
        return this.screen.lg ? this.heroImageDesktopUrl : this.heroImageMobileUrl
      },
      heroImageDesktopUrl() {
        return this.seasonalMarketData?.image.url ?? '/static/market/market-hero-desktop-1.gif'
      },
      heroImageMobileUrl() {
        return (
          this.seasonalMarketData?.mobile_image.url ?? '/static/market/market-hero-mobile-1.gif'
        )
      },
    },
    created() {
      this.loadSeasonalMarketData()
    },
    methods: {
      ...call('prismic', ['loadSeasonalMarketData']),
    },
  }
</script>

<template>
  <section class="bg-dawn-lt2">
    <div class="flex flex-col lg:flex-row">
      <!-- Mobile header -->
      <div v-if="!screen.lg" class="header-wrapper">
        <div v-if="formattedMarketplaceEndDate" class="subheading">
          Shop until {{ formattedMarketplaceEndDate }} at 11:59 p.m. PT
        </div>
        <h1 class="header">
          {{ header }}
        </h1>
      </div>
      <BaseImage
        :src="image"
        alt=""
        responsive="lg"
        :sizes="{ default: '100vw', lg: '40vw' }"
        background-transparent
        class="w-full lg:max-w-82 xl:max-w-105"
      />
      <div class="flex flex-col-reverse lg:flex-col w-full">
        <!-- Hero text and widgets -->
        <div class="flex flex-col items-center justify-center h-full">
          <!-- Desktop header -->
          <div v-if="screen.lg" class="header-wrapper">
            <div v-if="formattedMarketplaceEndDate" class="subheading">
              Shop until {{ formattedMarketplaceEndDate }} at 11:59 p.m. PT
            </div>
            <h1 class="header"> {{ header }} </h1>
          </div>

          <!-- Widgets -->
          <div class="flex flex-col lg:flex-row w-full lg:w-auto bg-dawn-lt2 p-2">
            <div :class="`flex ${showCountdown ? 'lg:grid lg:grid-cols-5' : ''} mb-2`">
              <!-- Countdown -->
              <div v-if="showCountdown" class="widgets w-2/3 lg:w-auto lg:col-span-2">
                <div class="widgets-subheading">Market closes in:</div>
                <WidgetCountdown :value="marketplaceSecondsLeft" />
              </div>
              <!-- Desktop free shipping progress bar -->
              <div
                v-if="screen.lg && minTotalForFreeShipping"
                class="widgets mx-2 xl:mx-4 lg:col-span-2"
              >
                <div class="widgets-subheading">Get free shipping!</div>
                <MarketShippingProgress />
              </div>
              <!-- Savings -->
              <div :class="`widgets lg:w-auto ${showCountdown ? 'ml-2 lg:m-0 w-1/3' : 'w-full'}`">
                <div class="widgets-subheading">Your savings:</div>
                <WidgetAmount :value="cartSavings" />
              </div>
            </div>
            <!-- Mobile free shipping progress bar -->
            <div v-if="!screen.lg && minTotalForFreeShipping" class="widgets w-full">
              <div class="widgets-subheading">Get free shipping!</div>
              <MarketShippingProgress />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="postcss">
  .header-wrapper {
    @apply mt-10px flex flex-col items-center justify-center;
  }

  .subheading {
    @apply mb-10px text-xs leading-tight;

    @screen 2xs {
      @apply text-sm;
    }

    @screen xs {
      @apply text-md;
    }

    @screen xl {
      @apply mb-0 text-lg;
    }
  }

  .header {
    @apply mb-3 font-heading font-semibold text-4xl leading-none text-center;

    @screen 2xs {
      @apply text-5xl;
    }

    @screen xs {
      @apply text-7xl;
    }

    @screen lg {
      @apply mb-22px;
    }

    @screen xl {
      @apply mb-26px text-8xl leading-snug;
    }
  }

  .widgets {
    @apply flex flex-col items-center bg-dawn py-10px px-1;

    @screen 2xs {
      @apply px-3;
    }
    @screen xs {
      @apply px-4;
    }
  }

  .widgets-subheading {
    @apply mb-2 font-semibold text-3xs;

    @screen xl {
      @apply text-sm;
    }
  }
</style>

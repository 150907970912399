<script>
  import { get, sync, call } from 'vuex-pathify'
  import FavoriteToggle from '@/components/marketplace/FavoriteToggle'
  import screen from '@/helpers/screen'

  /**
   * A card representing a product, including a slider of images and some information about the product such as its brand, name, price, etc. Accepts a `type` prop to achieve different layouts (current options: `upsell`, `marketplace`). Defaults to `upsell`.
   */
  export default {
    components: {
      FavoriteToggle,
    },
    props: {
      /**
       * Contains all data about the product.
       * title: string,
       * brand: string,
       * retailValue: number,
       * defaultVariant: {title: string, price: number},
       * description: string,
       * tags: array of strings,
       * images: array of strings (S3 URLs)
       */
      product: {
        type: Object,
        required: true,
      },
      /**
       * The product card’s type.
       * `marketplace` cards are links; they don’t have a “Add to cart” button by default but they can with the `hasAddToCartButton` prop; they also have tags and a favorite button.
       * `upsell` cards are not links; they have a toggle button to add/remove the product to/from the cart; they also have a border, a “X% OFF” sticker at the top, and an info icon that toggles the product’s description.
       */
      type: {
        type: String,
        default: 'marketplace',
        validator: (value) => ['marketplace', 'upsell'].includes(value),
      },
      /** The rank of the product, when listed in a “Top X Products” context. */
      rank: {
        type: Number,
        default: undefined,
      },
      /** Whether the card has an image slider instead of a single image. */
      hasSlider: {
        type: Boolean,
        default: false,
      },
      /** Whether the card has a “Add to cart” button. Only if `type` is `marketplace` (`upsell` cards always have a button). */
      hasAddToCartButton: {
        type: Boolean,
        default: false,
      },
      /** Whether the card has a "Quick Add" or "Add to Waitlist" button on hover. Only if `type` is `marketplace`. */
      hasQuickAddButton: {
        type: Boolean,
        default: false,
      },
      /** Whether to force the “product is in cart” state. */
      isInCart: {
        type: Boolean,
        default: false,
      },
      /** Whether to block adding more to cart */
      isMaxInCart: {
        type: Boolean,
        default: false,
      },
      /** Whether this card is being used in a PLP */
      isInPlp: {
        type: Boolean,
        default: false,
      },
      /** Whether the product title should be truncated to 1 line and end in ellipses */
      truncateProductTitle: {
        type: Boolean,
        default: false,
      },
      /** Whether users can favorite the product or not */
      hasFavoriteIcon: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        addedToCartOrWaitlist: false,
        addingToCartOrWaitlist: false,
        addedTimeout: null,
        isFavorited: null,
      }
    },
    computed: {
      ...get('account', ['favorites', 'hasMarketAccess']),
      ...get('marketplace', ['allTags']),
      ...get('marketplaceCart', ['variantCanAddRushShipping']),
      ...get('upsell', ['finalLineItems']),
      ...get('feature', ['flags']),
      ...sync('general', ['preventNavigation']),
      screen,
      isShowQuantitySelectorEnabled() {
        return this.flags['show-quantity-selector'] ?? false
      },
      variant() {
        return this.product.defaultVariant
      },
      percentOff() {
        const discountAmount = this.product.retailValue - this.variant.price
        return Math.round((discountAmount / this.product.retailValue) * 100)
      },
      almostOut() {
        return this.product.tags.includes('almost-out')
      },
      soldOut() {
        return this.product.tags.includes('sold-out')
      },
      isSoldOut() {
        return this.variant.stock <= 0 && !this.variant.hasUnlimitedStock
      },
      isCurrentlyFavorited() {
        return this.favorites.includes(this.product.id)
      },
      cartProduct() {
        return this.finalLineItems?.find(({ productId }) => productId === this.product.id)
      },
    },
    watch: {
      isCurrentlyFavorited: {
        immediate: true,
        handler() {
          this.isFavorited = this.isCurrentlyFavorited
        },
      },
    },
    methods: {
      ...call('upsell', ['updateQuantity']),
      addToCart() {
        this.preventNavigation = true
        this.addingToCartOrWaitlist = true
        clearTimeout(this.addedTimeout)
        try {
          this.$emit('addToCart', { variantId: this.variant.id })

          this.addingToCartOrWaitlist = false
          this.addedToCartOrWaitlist = true
          this.addedTimeout = setTimeout(() => {
            this.addedToCartOrWaitlist = false
          }, 5000)
        } finally {
          this.addingToCartOrWaitlist = false
        }
      },
      addToWaitlist() {
        this.preventNavigation = true
        clearTimeout(this.addedTimeout)

        this.$emit('addToWaitlist', { productId: this.product.id })

        this.addedToCartOrWaitlist = true
        this.addedTimeout = setTimeout(() => {
          this.addedToCartOrWaitlist = false
        }, 5000)
      },
      setFavorited(isFavorited) {
        this.isFavorited = isFavorited

        this.$emit('setFavorited', { productId: this.product.id, isFavorited: isFavorited })
      },
      addHandler() {
        return this.isSoldOut ? this.addToWaitlist() : this.addToCart()
      },
      updateQuantityInCart(type) {
        const quantity =
          type === 'add' ? this.cartProduct.quantity + 1 : this.cartProduct.quantity - 1
        this.updateQuantity({
          variantId: this.variant.id,
          quantity,
        })
        if (quantity < 1) {
          this.$emit('clearRemovedLineItems')
        }
      },
      viewDetails() {
        this.$emit('viewDetails', this.product)
      },
    },
  }
</script>

<template>
  <div class="flex flex-col flex-grow">
    <template v-if="type === 'upsell'">
      <BaseSticker
        v-if="percentOff > 0"
        class="z-20 absolute left-0 top-0 -mt-7px md:-mt-14px -ml-2 md:-ml-15px pointer-events-none"
      >
        {{ percentOff }}% <br />
        OFF
      </BaseSticker>
    </template>

    <component
      :is="type === 'upsell' ? 'BaseCardSelectable' : 'BaseCard'"
      :selected="type === 'upsell' ? isInCart : undefined"
      :disabled="type === 'upsell' ? isSoldOut : undefined"
      price-last
      :price-centered="type === 'upsell' && !screen.md"
      :show-quantity-selector="type === 'upsell' && isInCart && isShowQuantitySelectorEnabled"
      :quantity-in-selector="type === 'upsell' && isInCart ? cartProduct.quantity : 0"
      @click="type !== 'upsell' ? viewDetails : undefined"
      @addToCart="updateQuantityInCart('add')"
      @removeFromCart="updateQuantityInCart('remove')"
      @select="$emit('select')"
      @clickDetails="viewDetails"
    >
      <template v-if="product.images.length > 0" v-slot:image>
        <BaseSlider v-if="hasSlider && product.images.length >= 1">
          <BaseSliderSlide v-for="(imageUrl, imageIndex) in product.images" :key="imageIndex">
            <BaseImage
              :src="imageUrl"
              :alt="product.title"
              :aspect-ratio="1"
              responsive="md"
              :sizes="{ default: '50vw', md: '25vw' }"
            />
          </BaseSliderSlide>
        </BaseSlider>

        <div class="group overflow-hidden" data-cy="product-card-image">
          <BaseImage
            v-if="!hasSlider"
            :src="product.images[0]"
            :alt="product.title"
            :aspect-ratio="1"
            responsive="md"
            :sizes="{ default: '50vw', md: '25vw' }"
          />
          <div
            v-if="!hasAddToCartButton && hasQuickAddButton"
            class="absolute inset-x-0 bottom-0 opacity-0 md:group-hover:opacity-100 md:group-hover:translate-y-0 transform transition duration-400 translate-y-full z-10 m-1"
          >
            <BaseButton
              :disabled="addingToCartOrWaitlist || (!isSoldOut && isMaxInCart)"
              :icon-size="4"
              height="short"
              @click="addHandler"
            >
              <template v-if="addedToCartOrWaitlist" v-slot:icon>
                <IconCheckmarkThick />
              </template>
              <template v-else-if="!addingToCartOrWaitlist && !addedToCartOrWaitlist" v-slot:icon>
                <IconPlus />
              </template>
              <span class="truncate">
                <span v-if="!isSoldOut && isMaxInCart"> Max in cart </span>
                <span v-else-if="addingToCartOrWaitlist">Adding...</span>
                <span v-else-if="addedToCartOrWaitlist">Added!</span>
                <span v-else>{{ isSoldOut ? 'Add to waitlist' : 'Quick add' }}</span>
              </span>
            </BaseButton>
          </div>
          <div
            v-if="product.images[1]"
            class="absolute inset-0 opacity-0 md:group-hover:opacity-100 transition duration-400"
          >
            <BaseImage
              :src="product.images[1]"
              :alt="product.title"
              :aspect-ratio="1"
              responsive="md"
              :sizes="{ default: '50vw', md: '25vw' }"
            />
          </div>
        </div>
      </template>

      <!-- Tags only render on `marketplace` cards -->
      <template v-if="type === 'marketplace'" v-slot:tags>
        <BaseTag v-if="percentOff > 0" :label="`${percentOff}% off`" color="primary" />
        <BaseTag v-if="almostOut" label="ALMOST OUT" color="spring" text-color="black" />
        <BaseTag v-if="soldOut" label="SOLD OUT" color="gray-opacity68" />
      </template>

      <template v-if="rank" v-slot:rank>
        {{ rank }}
      </template>

      <template v-if="hasMarketAccess" v-slot:price>
        <div
          :class="`mt-2 flex items-center ${type === 'upsell' ? 'md:items-end' : ''} leading-none`"
        >
          <span
            :class="`font-semibold mr-5px text-sm ${type === 'upsell' ? 'md:text-17px' : ''}`"
            >{{ $formatPrice(variant.price) }}</span
          >
          <s
            v-if="product.retailValue && product.retailValue > variant.price"
            :class="`font-light text-sm ${type === 'upsell' ? 'md:text-2xs md:mb-1px' : ''}`"
          >
            {{ $formatPrice(product.retailValue) }}
          </s>
          <template v-if="variantCanAddRushShipping(variant)">
            <span class="ml-3px mr-2px text-sm">&#8729;</span>
            <BaseIcon :size="5" class="mt-1px" data-cy="product-rush-shipping">
              <IconRushShipping />
            </BaseIcon>
            <span v-if="screen.lg && isInPlp" class="text-xs ml-1 font-light">
              Holiday Delivery
            </span>
          </template>
        </div>
      </template>

      <template v-slot:kicker>
        <div
          v-if="type !== 'upsell'"
          class="uppercase text-black text-xs font-light"
          data-cy="product-brand"
        >
          {{ product.brand }}
        </div>
      </template>

      <template v-slot:title>
        <div
          v-if="type !== 'upsell'"
          :class="`text-xs md:text-sm mb-2px font-light ${
            truncateProductTitle ? 'truncate w-full pr-2' : ''
          }`"
          data-cy="product-title"
        >
          {{ product.title }}
        </div>
        <div v-else class="-ml-2 min-h-9 px-3 md:px-2">
          <div
            class="text-center md:text-left text-xs md:text-sm font-normal md:font-medium overflow-hidden ellipsis line-clamp-2"
          >
            {{ product.brand }} &#8729; {{ product.title }}
          </div>
        </div>
      </template>

      <template v-if="hasAddToCartButton" v-slot:footer>
        <div class="pt-4">
          <BaseButton
            :disabled="addingToCartOrWaitlist || (!isSoldOut && isMaxInCart)"
            @click="addHandler"
          >
            <!-- @slot Optional. If a “Add to cart” or “Add to waitlist” button is displayed, this slot can be used to override its label. -->
            <slot name="buttonLabel">
              <span v-if="!isSoldOut && isMaxInCart"> Max in cart </span>
              <span v-else-if="addingToCartOrWaitlist">Adding...</span>
              <span v-else-if="addedToCartOrWaitlist">Added!</span>
              <template v-else>{{ isSoldOut ? 'Add to waitlist' : 'Add to cart' }}</template>
            </slot>
          </BaseButton>
        </div>
      </template>

      <template v-if="type === 'upsell'" v-slot:buttonLabel>
        <slot v-if="isSoldOut" name="buttonLabel">Sold out</slot>
        <template v-else-if="isInCart">
          <BaseIcon :size="4" class="mb-2px inline-block align-middle">
            <IconCheckmark />
          </BaseIcon>
          <slot name="buttonLabel">Added</slot>
        </template>
        <slot v-else name="buttonLabel">Add to Cart</slot>
      </template>
    </component>
    <div
      v-if="type === 'upsell'"
      class="mt-3 flex justify-center leading-none font-medium text-2xs"
    >
      <BaseLink :tabindex="0" @click="viewDetails"> View Details </BaseLink>
    </div>

    <FavoriteToggle
      v-if="hasFavoriteIcon"
      class="z-20 absolute right-0 mr-9px top-0 mt-1 md:mr-13px"
      :label="product.title"
      :is-favorited="isFavorited"
      @change="setFavorited"
    />
  </div>
</template>
